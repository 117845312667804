import React, { useState, useEffect } from 'react';
import { fetchUsers, fetchRoles, addUser, updateUser, deleteUser } from '../services/api';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Button, FormHelperText, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Edit, Delete, Add, ContentCopy, WhatsApp, Email, CheckCircleOutline } from '@mui/icons-material';
import CustomButton from './CustomButton';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: 16,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(8.5px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: 'var(--color-primary)',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 700,
}));

const AddButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: 'var(--color-accent)',
  color: 'var(--color-primary)',
  '&:hover': {
    backgroundColor: 'var(--color-secondary)',
  },
}));

const SuccessDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3),
  },
  '& .MuiDialogTitle-root': {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
}));

const SuccessIcon = styled(CheckCircleOutline)(({ theme }) => ({
  fontSize: 48,
  color: theme.palette.success.main,
  marginBottom: theme.spacing(2),
}));

const ActionButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  '& .MuiButton-startIcon': {
    marginRight: theme.spacing(1),
  },
}));

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState({ username: '', email: '', role_id: '', password: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [newUserCredentials, setNewUserCredentials] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    fetchUserData();
    fetchRoleData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetchUsers();
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchRoleData = async () => {
    try {
      const response = await fetchRoles();
      setRoles(response.data);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const handleOpenDialog = (user = { username: '', email: '', role_id: '', password: '' }) => {
    setCurrentUser(user);
    setIsEditing(!!user.id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentUser({ username: '', email: '', role_id: '', password: '' });
    setIsEditing(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentUser({ ...currentUser, [name]: value });
    if (name === 'password') {
      setPasswordError(validatePassword(value));
    }
  };

  const validatePassword = (password) => {
    if (password.length < 8) {
      return 'Password must be at least 8 characters long';
    }
    if (!/[A-Z]/.test(password)) {
      return 'Password must contain at least one uppercase letter';
    }
    if (!/[a-z]/.test(password)) {
      return 'Password must contain at least one lowercase letter';
    }
    if (!/[0-9]/.test(password)) {
      return 'Password must contain at least one number';
    }
    if (!/[!@#$%^&*]/.test(password)) {
      return 'Password must contain at least one special character (!@#$%^&*)';
    }
    return '';
  };

  const handleSubmit = async () => {
    if (!isEditing && passwordError) {
      return;
    }
    try {
      if (isEditing) {
        await updateUser(currentUser.id, currentUser);
        setSnackbar({ open: true, message: 'User updated successfully', severity: 'success' });
      } else {
        const response = await addUser(currentUser);
        setNewUserCredentials(response.data);
        setSuccessDialogOpen(true);
      }
      fetchUserData();
      handleCloseDialog();
    } catch (error) {
      console.error('Error saving user:', error);
      const errorMessage = error.response?.data?.error || error.message || 'An unknown error occurred';
      setSnackbar({ open: true, message: `Error: ${errorMessage}`, severity: 'error' });
    }
  };

  const handleEditUser = async () => {
    try {
      await updateUser(currentUser.id, currentUser);
      setSnackbar({ open: true, message: 'User updated successfully', severity: 'success' });
      fetchUserData();
      handleCloseDialog();
    } catch (error) {
      console.error('Error updating user:', error);
      setSnackbar({ open: true, message: `Error: ${error.message}`, severity: 'error' });
    }
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await deleteUser(userId);
        setSnackbar({ open: true, message: 'User deleted successfully', severity: 'success' });
        fetchUserData();
      } catch (error) {
        console.error('Error deleting user:', error);
        setSnackbar({ open: true, message: `Error: ${error.message}`, severity: 'error' });
      }
    }
  };

  const handleCopyCredentials = () => {
    const credentialsText = `Username: ${newUserCredentials.user.username}\nPassword: ${newUserCredentials.password}`;
    navigator.clipboard.writeText(credentialsText);
    setSnackbar({ open: true, message: 'Credentials copied to clipboard', severity: 'success' });
  };

  const handleShareWhatsApp = () => {
    const credentialsText = `Your new account credentials:\nUsername: ${newUserCredentials.user.username}\nPassword: ${newUserCredentials.password}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(credentialsText)}`;
    window.open(whatsappUrl, '_blank');
  };

  const handleShareEmail = () => {
    const subject = 'Your New Account Credentials';
    const body = `Your new account credentials:\n\nUsername: ${newUserCredentials.user.username}\nPassword: ${newUserCredentials.password}`;
    const mailtoUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoUrl;
  };

  return (
    <StyledContainer>
      <Title variant="h4" gutterBottom>
        User Management
      </Title>
      
      <CustomButton
        startIcon={<Add />}
        onClick={() => handleOpenDialog()}
        style={{ marginBottom: '20px' }}
      >
        Add New User
      </CustomButton>

      <StyledPaper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{roles.find(role => role.role_id === user.role_id)?.role_name}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenDialog(user)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteUser(user.id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledPaper>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{isEditing ? 'Edit User' : 'Add New User'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="username"
            label="Username"
            type="text"
            fullWidth
            value={currentUser.username}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            value={currentUser.email}
            onChange={handleInputChange}
          />
          {!isEditing && (
            <>
              <TextField
                margin="dense"
                name="password"
                label="Password"
                type="password"
                fullWidth
                value={currentUser.password}
                onChange={handleInputChange}
                error={!!passwordError}
                helperText={passwordError}
              />
              <FormHelperText>
                Password must be at least 8 characters long, contain uppercase and lowercase letters, numbers, and special characters.
              </FormHelperText>
            </>
          )}
          <Select
            margin="dense"
            name="role_id"
            label="Role"
            fullWidth
            value={currentUser.role_id}
            onChange={handleInputChange}
          >
            {roles.map((role) => (
              <MenuItem key={role.role_id} value={role.role_id}>
                {role.role_name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleCloseDialog}>Cancel</CustomButton>
          <CustomButton 
            onClick={handleSubmit} 
            disabled={!isEditing && (!!passwordError || !currentUser.password)}
          >
            {isEditing ? 'Update' : 'Add'}
          </CustomButton>
        </DialogActions>
      </Dialog>

      <SuccessDialog open={successDialogOpen} onClose={() => setSuccessDialogOpen(false)}>
        <DialogTitle>
          <SuccessIcon />
          User Created Successfully
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            Username: {newUserCredentials?.user.username}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Password: {newUserCredentials?.password}
          </Typography>
          <ActionButton
            variant="outlined"
            startIcon={<ContentCopy />}
            onClick={handleCopyCredentials}
          >
            Copy to Clipboard
          </ActionButton>
          <ActionButton
            variant="outlined"
            startIcon={<WhatsApp />}
            onClick={handleShareWhatsApp}
          >
            Share via WhatsApp
          </ActionButton>
          <ActionButton
            variant="outlined"
            startIcon={<Email />}
            onClick={handleShareEmail}
          >
            Share via Email
          </ActionButton>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => setSuccessDialogOpen(false)}>Close</CustomButton>
        </DialogActions>
      </SuccessDialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </StyledContainer>
  );
}

export default UserManagement;