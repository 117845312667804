import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  getLocationById, 
  updateReplenishLevelsById, 
  getReplenishMode, 
  updateReplenishMode, 
  updateAutoReplenishSettings
} from '../services/api';
import { 
  Container, Typography, Grid, Card, CardContent, TextField, 
  IconButton, Box, CircularProgress, Switch, FormControlLabel,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  RadioGroup, Radio, FormControl, FormLabel
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import { styled } from '@mui/material/styles';
import CustomButton from './CustomButton';

const StyledContainer = styled(Container)(() => ({
  paddingTop: 32, // 4 * 8
  paddingBottom: 32, // 4 * 8
}));

const StyledCard = styled(Card)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: 16,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(8.5px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  padding: 16, // 2 * 8
}));

function ReplenishLevels() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingReplenishLevels, setEditingReplenishLevels] = useState({});
  const [message, setMessage] = useState(null);
  const [isAutomatic, setIsAutomatic] = useState(null);
  const [autoReplenishType, setAutoReplenishType] = useState('inventory');
  const [inventoryAmount, setInventoryAmount] = useState('');
  const [monthsOfSales, setMonthsOfSales] = useState('');

  useEffect(() => {
    fetchLocationDetails();
  }, [id]);

  const fetchLocationDetails = async () => {
    try {
      setLoading(true);
      const [locationData, replenishModeData] = await Promise.all([
        getLocationById(id),
        getReplenishMode(id).catch(err => {
          console.error('Error fetching replenish mode:', err);
          return { is_automatic: false };
        })
      ]);
      
      console.log('Fetched location details:', locationData);
      console.log('Fetched replenish mode:', replenishModeData);
      
      setLocation(locationData);
      setEditingReplenishLevels(locationData.ReplenishLevels || {});
      
      setIsAutomatic(replenishModeData.is_automatic);
      console.log('Set isAutomatic to:', replenishModeData.is_automatic);
    } catch (err) {
      console.error('Error fetching location details:', err);
      setError('Failed to fetch location details. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleReplenishLevelChange = (category, value) => {
    setEditingReplenishLevels(prev => ({
      ...prev,
      [category]: value === '' ? '' : parseInt(value, 10)
    }));
  };

  const saveReplenishLevels = async () => {
    try {
      setMessage('Updating replenish levels...');

      const updatedLevels = {
        G: editingReplenishLevels.G ?? location.ReplenishLevels?.G,
        M: editingReplenishLevels.M ?? location.ReplenishLevels?.M,
        G2: editingReplenishLevels.G2 ?? location.ReplenishLevels?.G2,
        M2: editingReplenishLevels.M2 ?? location.ReplenishLevels?.M2
      };

      console.log('Updated Levels:', updatedLevels);

      const result = await updateReplenishLevelsById(id, updatedLevels);

      console.log('API Response:', result);

      if (result.success) {
        setMessage(result.message);
        setLocation(prevLocation => ({
          ...prevLocation,
          ReplenishLevels: result.updatedLevels
        }));
      } else {
        setError(result.message);
      }
    } catch (err) {
      console.error('Error updating replenish levels:', err);
      setError('Failed to update replenish levels.');
    }
  };

  const resetReplenishLevels = () => {
    setEditingReplenishLevels(location.ReplenishLevels || {});
  };

  const handleModeChange = async (event) => {
    const newMode = event.target.checked;
    try {
      const result = await updateReplenishMode(id, newMode);
      if (result.success) {
        setIsAutomatic(newMode);
        setMessage(`Replenish mode updated to ${newMode ? 'Automatic' : 'Manual'}`);
      } else {
        setError(result.message || 'Failed to update replenish mode');
      }
    } catch (err) {
      console.error('Error updating replenish mode:', err);
      setError('Failed to update replenish mode. Please try again.');
    }
  };

  const handleAutoReplenishTypeChange = (event) => {
    setAutoReplenishType(event.target.value);
  };

  const handleInventoryAmountChange = (event) => {
    const value = event.target.value;
    setInventoryAmount(value === '' ? '' : Math.max(0, parseInt(value, 10)));
    setMonthsOfSales('');
  };

  const handleMonthsOfSalesChange = (event) => {
    const value = event.target.value;
    setMonthsOfSales(value === '' ? '' : Math.max(0, parseFloat(value)));
    setInventoryAmount('');
  };

  const handleSaveAutoReplenish = async () => {
    try {
      setMessage('Updating auto replenish settings...');
      
      // Validate inputs before sending to the backend
      const validInventoryAmount = inventoryAmount === '' ? null : parseInt(inventoryAmount, 10);
      const validMonthsOfSales = monthsOfSales === '' ? null : parseFloat(monthsOfSales);

      if ((autoReplenishType === 'inventory' && validInventoryAmount === null) ||
          (autoReplenishType === 'sales' && validMonthsOfSales === null)) {
        setError('Please enter a valid number for the selected replenish type.');
        return;
      }

      // Log the user's action
      console.log(`User set automatic replenish level for location ${id}:`, {
        type: autoReplenishType,
        inventoryAmount: validInventoryAmount,
        monthsOfSales: validMonthsOfSales
      });

      const result = await updateAutoReplenishSettings(
        id,
        autoReplenishType,
        validInventoryAmount,
        validMonthsOfSales
      );
      
      if (result.success) {
        setMessage(result.message);
        setEditingReplenishLevels(result.updatedLevels);
        console.log(`Auto replenish settings updated successfully for location ${id}:`, result.updatedLevels);
      } else {
        setError(result.message || 'Failed to update auto replenish settings');
        console.error(`Failed to update auto replenish settings for location ${id}:`, result.message);
      }
    } catch (err) {
      console.error('Error updating auto replenish settings:', err);
      setError('Failed to update auto replenish settings. Please try again.');
    }
  };

  if (loading || isAutomatic === null) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!location) return <Typography color="error">Location not found</Typography>;

  const rows = ['G', 'M', 'G2', 'M2'].map((category, index) => ({
    id: index + 1,
    category,
    currentInventory: location.InventoryByCategory[category],
    replenishLevel: editingReplenishLevels[category] ?? ''
  }));

  return (
    <StyledContainer>
      <Typography variant="h4" gutterBottom>
        Replenish Levels for {location.name}
      </Typography>
      {message && <Typography color="secondary" gutterBottom>{message}</Typography>}
      {error && <Typography color="error" gutterBottom>{error}</Typography>}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledCard>
            <CardContent>
              <FormControlLabel
                control={
                  <Switch
                    checked={isAutomatic}
                    onChange={handleModeChange}
                    name="replenishMode"
                    color="primary"
                  />
                }
                label={isAutomatic ? "Automatic Mode" : "Manual Mode"}
              />
              {isAutomatic && (
                <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 2 }}>
                  <Typography variant="h6" gutterBottom>
                    Automatic Replenish Settings
                  </Typography>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Replenish Based On:</FormLabel>
                    <RadioGroup
                      row
                      value={autoReplenishType}
                      onChange={handleAutoReplenishTypeChange}
                    >
                      <FormControlLabel value="inventory" control={<Radio />} label="Inventory Amount" />
                      <FormControlLabel value="sales" control={<Radio />} label="Months of Sales" />
                    </RadioGroup>
                  </FormControl>
                  <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                    <TextField
                      label="Inventory Amount"
                      type="number"
                      value={inventoryAmount}
                      onChange={handleInventoryAmountChange}
                      variant="outlined"
                      size="small"
                      inputProps={{ min: 0, step: 1 }}
                      sx={{ mr: 2 }}
                      disabled={autoReplenishType !== 'inventory'}
                    />
                    <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>
                      units
                    </Typography>
                    <TextField
                      label="Months of Sales"
                      type="number"
                      value={monthsOfSales}
                      onChange={handleMonthsOfSalesChange}
                      variant="outlined"
                      size="small"
                      inputProps={{ min: 0, step: 0.1 }}
                      sx={{ mr: 2 }}
                      disabled={autoReplenishType !== 'sales'}
                    />
                    <Typography variant="body2" color="textSecondary">
                      months
                    </Typography>
                  </Box>
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <CustomButton onClick={handleSaveAutoReplenish}>
                        Save Auto Replenish Settings
                    </CustomButton>
                  </Box>
                </Box>
              )}
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table aria-label="replenish levels table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Current Inventory</TableCell>
                      <TableCell>Replenish Level</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.id}</TableCell>
                        <TableCell>{row.category}</TableCell>
                        <TableCell>{row.currentInventory}</TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            value={editingReplenishLevels[row.category] ?? ''}
                            onChange={(e) => handleReplenishLevelChange(row.category, e.target.value)}
                            size="small"
                            fullWidth
                            inputProps={{ min: 0 }}
                            disabled={isAutomatic}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              {!isAutomatic && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                  <IconButton onClick={resetReplenishLevels} color="secondary">
                    <UndoIcon />
                  </IconButton>
                  <IconButton onClick={saveReplenishLevels} color="primary">
                    <SaveIcon />
                  </IconButton>
                </Box>
              )}
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }}>
        <CustomButton onClick={() => navigate(`/locations/${id}`)}>
          Back to Location Details
        </CustomButton>
      </Box>
    </StyledContainer>
  );
}

export default ReplenishLevels;