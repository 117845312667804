import axios from 'axios';

// Use an environment variable to set the API base URL
const API_URL = 'https://pimbyeli.com/api';

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  timeout: 60000 // 60 seconds timeout
});

// Remove the API key from the default headers

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const login = async (credentials) => {
  const response = await api.post('/auth/login', credentials);
  if (response.data.token) {
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('user', JSON.stringify(response.data.user));
  }
  return response;
};

export const loginWithVerification = async (credentials) => {
  try {
    const response = await api.post('/auth/login-with-verification', credentials);
    console.log('Login with verification response:', response.data);
    // We don't set the token here because this is just the first step
    // The token will be set after the verification code is confirmed
    return response;
  } catch (error) {
    console.error('Login with verification error:', error.response?.data || error.message);
    throw error;
  }
};

export const verifyCode = async (verificationData) => {
  try {
    const response = await api.post('/auth/verify-code', verificationData);
    console.log('Verify code response:', response.data);
    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
    }
    return response;
  } catch (error) {
    console.error('Verify code error:', error.response?.data || error.message);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};

export const getLocations = async () => {
  try {
    const response = await api.get('/locations');
    console.log('API Response:', response.data);
    if (Array.isArray(response.data)) {
      return response.data;
    } else {
      console.error('Unexpected response format:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Error fetching locations:', error);
    return [];
  }
};

export const getMode = () => api.get('/settings/mode'); // Expected format: Object
export const updateMode = (mode) => api.put('/settings/mode', { mode }); // Expected format: Object
export const addOrUpdateSetting = (settingData) => api.post('/settings', settingData); // Expected format: Object


export const updateReplenishLevelsById = async (id, updatedLevels) => {
    try {
      const response = await api.put('/locations/change-replenish-levels', 
        { 
          db_location_id: id, 
          recommended_inventory_levels: updatedLevels 
        },
       // 5-minute timeout for this specific request
      );
      return response.data;
    } catch (error) {
      console.error('Error updating replenish levels:', error);
      throw error;
    }
  };

export const getAllReplenishLevels = () => api.get('/replenish-levels'); // Expected format: Array


export const updateReplenishLevelsAndFetchLocations = async () => {
  await api.post('/replenish-levels/update'); // Expected format: Object
  const response = await api.get('/locations'); // Expected format: Array
  return response.data; // Ensure this returns the data array
};

export const fetchUsers = () => api.get('/users'); // Expected format: Array
export const addUser = async (userData) => {
  try {
    const response = await api.post('/users', userData);
    return response;
  } catch (error) {
    console.error('Error adding user:', error);
    throw error;
  }
};
export const updateUser = (userId, userData) => api.put(`/users/${userId}`, userData); // Expected format: Object
export const deleteUser = (userId) => api.delete(`/users/${userId}`); // Expected format: Object
export const fetchRoles = () => api.get('/users/roles'); // Expected format: Array
export const getPosSystems = () => api.get('/pos'); // Expected format: Array
export const getAllSettings = () => api.get('/settings'); // Expected format: Array

export const getSetting = async (key) => {
  const response = await api.get(`/settings/${key}`); // Ensure this is correct
  return response;
};

export const getPOSData = async () => {
  return api.get('/pos');
};

export const syncPOSData = async () => {
  return api.post('/pos/sync');
};

export const deletePos = async (posId) => {
  try {
    console.log(`Deleting POS with ID: ${posId}`);
    const response = await api.delete(`/pos/${posId}`);
    console.log(`Successfully deleted POS with ID: ${posId}`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error deleting POS with ID ${posId}:`, error.response ? error.response.data : error.message);
    throw error;
  }
};

export const addPos = (posData) => api.post('/pos', posData);

export const getLocationById = async (id) => {
  try {
    console.log(`Sending request to fetch location by ID: ${id}`);
    const response = await api.get(`/locations/${id}`);
    console.log(`Received response for location ID ${id}:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error fetching location by ID ${id}:`, error.response ? error.response.data : error.message);
    throw error;
  }
};

export const runUpdateReplenishLevels = async () => {
  try {
    const response = await api.post('/api/run-update-replenish-levels', {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to run update replenish levels script');
    }
    return await response.json();
  } catch (error) {
    console.error('Error running update replenish levels script:', error);
    throw error;
  }
};

export const getTotalLocations = async () => {
  try {
    console.log('Fetching total locations and inventory');
    const response = await api.get('/locations/total');
    console.log('Received response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching total locations and inventory:', error);
    return { totalLocations: 0, totalInventory: 0 };
  }
};

export const getTotalOptimization = async () => {
  try {
    const response = await api.get('/optimization/total');
    return response.data;
  } catch (error) {
    console.error('Error fetching total optimization:', error);
    return { optimizationLevel: '0%' };
  }
};

export const getRecentUpdates = async () => {
  try {
    const response = await api.get('/updates/recent');
    console.log('API Response for recent updates:', response.data); // Add this line for debugging
    return response.data;
  } catch (error) {
    console.error('Error fetching recent updates:', error);
    throw error;
  }
};

export const getInventoryHistory = async () => {
  console.log('Fetching inventory history');
  try {
    const response = await api.get('/inventory/history');
    console.log('Inventory history response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching inventory history:', error);
    throw error;
  }
};

export const runReloadProcess = async () => {
  try {
    const response = await api.post('/reload-process');
    return response.data;
  } catch (error) {
    console.error('Error running reload process:', error);
    throw error;
  }
};

export const deleteLocation = async (locationId) => {
  try {
    const response = await api.delete(`/locations/${locationId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting location:', error);
    throw error;
  }
};

export const getSalesData = async (startDate, endDate, itemTypes, locations) => {
    try {
        const response = await api.get('/sales', {
            params: { startDate, endDate, itemTypes: itemTypes.join(','), locations: locations.join(',') }
        });
        console.log('Raw API response:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error in getSalesData:', error);
        throw error;
    }
};

export const updateLocationType = async (locationId, locationType) => {
  try {
    const response = await api.put(`/locations/${locationId}/type`, { locationType });
    return response.data;
  } catch (error) {
    console.error('Error updating location type:', error);
    throw error;
  }
};

export const generateOrderSheet = async (id, save = true) => {
  try {
    const response = await api.post(`/generate/order/${id}`, { save });
    return response.data.sheetLink;
  } catch (error) {
    console.error('Error generating order sheet:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const generateReplenishSheet = async (id, save = true) => {
  try {
    const response = await api.post(`/generate/replenish/${id}`, { save });
    return response.data.sheetLink;
  } catch (error) {
    console.error('Error generating replenish sheet:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const generateInventorySheet = async (id, save = true) => {
  try {
    const response = await api.post(`/generate/inventory/${id}`, { save });
    return response.data.sheetLink;
  } catch (error) {
    console.error('Error generating inventory sheet:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const getReplenishMode = async (locationId) => {
  try {
    const response = await api.get(`/replenish-levels/${locationId}/mode`);
    return response.data;
  } catch (error) {
    console.error('Error fetching replenish mode:', error);
    throw error;
  }
};

export const updateReplenishMode = async (locationId, isAutomatic) => {
  try {
    const response = await api.put(`/replenish-levels/${locationId}/mode`, { is_automatic: isAutomatic });
    return response.data;
  } catch (error) {
    console.error('Error updating replenish mode:', error);
    throw error;
  }
};

export const fetchDeletedLocations = async () => {
  try {
    console.log('Sending request to fetch deleted locations');
    const response = await api.get('/deleted'); // Ensure this endpoint matches the backend route
    console.log(`Received response with status: ${response.status}`);

    if (response.status !== 200) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = response.data;
    console.log(`Parsed response data: ${JSON.stringify(data)}`);
    return data;
  } catch (error) {
    console.error(`Error in fetchDeletedLocations: ${error.message}`, error);
    throw error;
  }
};

export const recoverLocation = async (locationId) => {
  try {
    const response = await api.post(`/recover/${locationId}`);
    return response.data;
  } catch (error) {
    console.error('Error recovering location:', error);
    throw new Error('Failed to recover location');
  }
};

export const calculatePercentages = async () => {
  try {
    const response = await api.post('/optimization/calculate-percentages');
    return response.data;
  } catch (error) {
    console.error('Error calculating percentages:', error);
    throw error;
  }
};

// Add this new function
export const getAllItems = async () => {
  try {
    const response = await api.get('/optimization/all-items');
    return response.data;
  } catch (error) {
    console.error('Error fetching all items:', error);
    throw error;
  }
};

export const getItemsByLocation = async () => {
  try {
    const response = await api.get('/optimization/items-by-location');
    return response.data;
  } catch (error) {
    console.error('Error fetching items by location:', error);
    throw error;
  }
};

// Add functions to interact with backend API
export const getMissingInventory = async (locationId) => {
    console.log(`Fetching missing inventory for location ${locationId}`);
    try {
        const response = await api.get(`/locations/${locationId}/missing-inventory`);
        console.log(`Successfully fetched missing inventory for location ${locationId}`, response.data);
        return response.data;
    } catch (error) {
        console.error(`Error fetching missing inventory for location ${locationId}:`, error);
        throw error
    }
};


export const createTransferOrder = async (orderData) => {
    console.log('Creating transfer order', orderData);
    try {
        const response = await api.post('/transfer-orders', orderData);
        console.log('Successfully created transfer order', response.data);
        return response.data;
    } catch (error) {
        console.error('Error creating transfer order:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export const getTransferOrders = async () => {
    console.log('Fetching transfer orders');
    try {
        const response = await api.get('/transfer-orders');
        console.log('Successfully fetched transfer orders', response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching transfer orders:', error);
        throw error;
    }
};

export const updateTransferOrderStatus = async (orderId, statusName) => {
    console.log(`Updating status of transfer order ${orderId} to ${statusName}`);
    try {
        const response = await api.put(`/transfer-orders/${orderId}/status`, { statusName });
        console.log(`Successfully updated status of transfer order ${orderId}`, response.data);
        return response.data;
    } catch (error) {
        console.error(`Error updating status of transfer order ${orderId}:`, error);
        throw error;
    }
};

export const getLocationsWithMissingItems = async () => {
  try {
    const response = await api.get('/locations/missing-items');
    console.log('Raw missing items data:', response.data);  // Add this line for debugging
    return response.data;
  } catch (error) {
    console.error('Error fetching locations with missing items:', error);
    throw error;
  }
};

export const getAvailableTransferItems = async (locationId) => {
  try {
    const response = await api.get(`/locations/${locationId}/available-transfers`);
    return response.data;
  } catch (error) {
    console.error('Error fetching available transfer items:', error);
    throw error;
  }
};

export const updateAutoReplenishSettings = async (locationId, autoReplenishType, inventoryAmount, monthsOfSales) => {
    try {
        const response = await api.put(`/locations/${locationId}/auto-replenish`, {
            autoReplenishType,
            inventoryAmount,
            monthsOfSales
        });
        return response.data;
    } catch (error) {
        console.error('Error updating auto replenish settings:', error);
        throw error;
    }
};

export const updateInventoryForLocation = async (locationId) => {
  try {
    const response = await api.post(`/locations/${locationId}/update-inventory`);
    return response.data;
  } catch (error) {
    console.error('Error starting inventory update for location:', error);
    throw error;
  }
};

export const getInventoryUpdateStatus = async (locationId) => {
  try {
    const response = await api.get(`/locations/${locationId}/inventory-update-status`);
    return response.data;
  } catch (error) {
    console.error('Error getting inventory update status:', error);
    throw error;
  }
};

export default api;