import React, { useState, useEffect } from 'react';
import { Typography, Button, CircularProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { TabView, TabPanel } from 'primereact/tabview';
import { MultiSelect } from 'primereact/multiselect';
import { calculatePercentages, getAllItems, getItemsByLocation, getLocations } from '../services/api';

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
});

function Optimize() {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [allItems, setAllItems] = useState([]);
  const [itemsByLocation, setItemsByLocation] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
    name: { value: null, matchMode: 'contains' },
    type: { value: null, matchMode: 'contains' },
    location_name: { value: null, matchMode: 'contains' },
    inventory_level: { value: null, matchMode: 'contains' },
    replenish_level: { value: null, matchMode: 'contains' },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [allItemsData, itemsByLocationData, locationsData] = await Promise.all([
        getAllItems(),
        getItemsByLocation(),
        getLocations(),
      ]);
      setAllItems(allItemsData);
      setItemsByLocation(itemsByLocationData);
      setLocations(locationsData);
      setSelectedLocations(locationsData.map(location => location.db_location_id));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  const handleOptimize = async () => {
    setLoading(true);
    try {
      const data = await calculatePercentages();
      setResult(data);
      fetchData();
    } catch (error) {
      console.error('Error calculating percentages:', error);
    }
    setLoading(false);
  };

  const handleLocationChange = (event) => {
    setSelectedLocations(event.value);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = (tableTitle) => {
    return (
      <div className="flex justify-content-between align-items-center">
        <h5 className="m-0">{tableTitle}</h5>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
        </span>
      </div>
    );
  };

  // Modify the filteredItemsByLocation calculation
  const filteredItemsByLocation = React.useMemo(() => {
    return itemsByLocation.filter(item =>
      selectedLocations.includes(item.location_id)
    );
  }, [itemsByLocation, selectedLocations]);

  return (
    <StyledContainer>
      <Typography variant="h4" component="h1" gutterBottom>
        Optimize
      </Typography>
      <Button variant="contained" color="primary" onClick={handleOptimize} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : 'Calculate Percentages'}
      </Button>
      {result && (
        <div>
          <Typography variant="h6" gutterBottom>
            Results:
          </Typography>
          <Typography>
            Total Items: {result.totalItems}
          </Typography>
          <Typography>
            Total Sum of Percent of Type: {result.totalPercentOfType.toFixed(2)}
          </Typography>
          <Typography>
            Total Sum of Percent of Total: {result.totalPercentOfTotal.toFixed(2)}
          </Typography>
        </div>
      )}
      <TabView style={{ width: '100%', marginTop: '2rem' }}>
        <TabPanel header="All Items">
          <DataTable
            value={allItems}
            paginator
            rows={10}
            dataKey="id"
            filters={filters}
            filterDisplay="menu"
            loading={loading}
            globalFilterFields={['name', 'type']}
            header={renderHeader('All Items')}
            emptyMessage="No items found."
            responsiveLayout="scroll"
          >
            <Column field="name" header="Name" filter filterPlaceholder="Search by name" sortable />
            <Column field="type" header="Type" filter filterPlaceholder="Search by type" sortable />
            <Column field="size" header="Size" sortable />
            <Column field="brim" header="Brim" sortable />
            <Column field="shape" header="Shape" sortable />
            <Column field="total_inventory" header="Total Inventory" sortable />
            <Column field="total_replenish_level" header="Total Replenish Level" sortable />
            <Column field="percent_of_total" header="% of Total" sortable body={(rowData) => `${rowData.percent_of_total}%`} />
            <Column field="percent_of_type" header="% of Type" sortable body={(rowData) => `${rowData.percent_of_type}%`} />
            <Column field="total_sales" header="Total Sales" sortable />
          </DataTable>
        </TabPanel>
        <TabPanel header="Items by Location">
          <FormControl variant="outlined" style={{ margin: '1rem', minWidth: 200 }}>
            <InputLabel>Select Locations</InputLabel>
            <MultiSelect
              value={selectedLocations}
              options={locations}
              onChange={handleLocationChange}
              optionLabel="name"
              optionValue="db_location_id"
              placeholder="Select Locations"
              className="w-full md:w-20rem"
            />
          </FormControl>
          <DataTable
            value={filteredItemsByLocation}
            paginator
            rows={10}
            dataKey="id" // Change this to a unique identifier
            filters={filters}
            filterDisplay="menu"
            loading={loading}
            globalFilterFields={['item_name', 'item_type', 'location_name', 'inventory_level', 'replenish_level']}
            header={renderHeader('Items by Location')}
            emptyMessage="No items found."
            responsiveLayout="scroll"
          >
            <Column field="item_name" header="Name" filter filterPlaceholder="Search by name" sortable />
            <Column field="item_type" header="Type" filter filterPlaceholder="Search by type" sortable />
            <Column field="location_name" header="Location" filter filterPlaceholder="Search by location" sortable />
            <Column field="inventory_level" header="Inventory Level" filter filterPlaceholder="Search by inventory" sortable />
            <Column field="replenish_level" header="Replenish Level" filter filterPlaceholder="Search by replenish" sortable />
          </DataTable>
        </TabPanel>
      </TabView>
    </StyledContainer>
  );
}

export default Optimize;