import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Button, Box, IconButton, Menu, MenuItem, Tooltip, List, ListItem, ListItemIcon, ListItemText, Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Home as HomeIcon,
  LocationOn as LocationOnIcon,
  CompareArrows as TransferOrdersIcon,
  PointOfSale as PointOfSaleIcon,
  Group as GroupIcon,
  BarChart as BarChartIcon,
  Tune as TuneIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon
} from '@mui/icons-material';
import CenteredNavCard from './CenteredNavCard';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'var(--color-primary)',
}));

const Logo = styled(Link)(({ theme }) => ({
  flexGrow: 1,
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 700,
  fontSize: '1.5rem',
  color: 'var(--color-white)',
  textDecoration: 'none',
  transition: 'all 0.3s ease',
  '&:hover': {
    color: 'rgba(255, 255, 255, 0.8)',
    transform: 'translateY(-2px)',
  },
}));

const NavButton = styled(Button)(({ theme }) => ({
  color: 'var(--color-white)',
  margin: '0 5px',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    transform: 'translateY(-2px)',
  },
}));

const SettingsIconButton = styled(IconButton)(({ theme }) => ({
  color: 'var(--color-white)',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    transform: 'translateY(-2px) rotate(15deg)',
  },
}));

const MobileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: '8px',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    transform: 'translateX(5px)',
  },
}));

const Navigation = ({ user, onLogout }) => {
  const [showNavCard, setShowNavCard] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  const handleSettingsClick = (event) => {
    event.preventDefault();
    setShowNavCard(true);
  };

  const handleCloseNavCard = () => {
    setShowNavCard(false);
  };

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  const menuItems = [
    { text: 'Home', path: '/', icon: <HomeIcon />, adminOnly: false },
    { text: 'Locations', path: '/locations', icon: <LocationOnIcon />, adminOnly: false },
    { text: 'Transfer Orders', path: '/transfer-orders', icon: <TransferOrdersIcon />, adminOnly: false },
    { text: 'Sales', path: '/sales', icon: <BarChartIcon />, adminOnly: true },
    { text: 'Optimize', path: '/optimize', icon: <TuneIcon />, adminOnly: true },
    { text: 'Settings', path: '/settings', icon: <SettingsIcon />, adminOnly: true },
    { text: 'POS Management', path: '/pos-management', icon: <PointOfSaleIcon />, adminOnly: true },
    { text: 'User Management', path: '/user-management', icon: <GroupIcon />, adminOnly: true },
  ];

  const filteredMenuItems = menuItems.filter(item => !item.adminOnly || (user && user.role === 'admin'));

  const renderMobileMenu = () => (
    <Drawer anchor="right" open={mobileMenuOpen} onClose={handleMobileMenuClose}>
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={handleMobileMenuClose}
        onKeyDown={handleMobileMenuClose}
      >
        <List>
          {filteredMenuItems.map((item, index) => (
            <StyledListItem key={index} component={Link} to={item.path}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </StyledListItem>
          ))}
          <StyledListItem onClick={onLogout}>
            <ListItemIcon><LogoutIcon /></ListItemIcon>
            <ListItemText primary="Logout" />
          </StyledListItem>
        </List>
      </Box>
    </Drawer>
  );

  return (
    <StyledAppBar position="static">
      <Toolbar>
        <Box sx={{ display: { xs: 'none', md: 'flex' }, width: '100%' }}>
          <Logo to="/">PIM BY ELI</Logo>
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            {filteredMenuItems.slice(0, 5).map((item, index) => (
              <NavButton
                key={index}
                component={Link}
                to={item.path}
                startIcon={item.icon}
                sx={{
                  backgroundColor: location.pathname === item.path ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                }}
              >
                {item.text}
              </NavButton>
            ))}
          </Box>
          {user && (
            <Box>
              <Tooltip title="Settings & Logout">
                <SettingsIconButton
                  size="large"
                  onClick={handleSettingsClick}
                  color="inherit"
                >
                  <SettingsIcon />
                </SettingsIconButton>
              </Tooltip>
            </Box>
          )}
        </Box>

        {/* Mobile view */}
        <MobileContainer sx={{ display: { xs: 'flex', md: 'none' } }}>
          <Logo to="/">PIM BY ELI</Logo>
          <IconButton
            size="large"
            onClick={handleMobileMenuToggle}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
        </MobileContainer>

        {/* Mobile menu */}
        {renderMobileMenu()}
      </Toolbar>
      {showNavCard && <CenteredNavCard onClose={handleCloseNavCard} handleLogout={onLogout} />}
    </StyledAppBar>
  );
};

export default Navigation;
