import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, Card, CardContent, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Box } from '@mui/material';
import { Delete, Add, Warning } from '@mui/icons-material';
import { styled, keyframes } from '@mui/material/styles';
import { getPosSystems, deletePos, addPos } from '../services/api';
import CustomButton from './CustomButton';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: 16,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(8.5px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: 'var(--color-primary)',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 700,
}));

const StyledLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const WarningDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    padding: theme.spacing(2),
    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  },
}));

const WarningIcon = styled(Warning)(({ theme }) => ({
  fontSize: 48,
  color: theme.palette.warning.main,
  marginBottom: theme.spacing(2),
}));

const typeAnimation = keyframes`
  from { width: 0; }
  to { width: 100%; }
`;

const AnimatedText = styled(Typography)(({ theme, delay = 0 }) => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  animation: `${typeAnimation} 2s steps(40, end) ${delay}s forwards`,
  width: 0,
}));

function POSManagement() {
  const [posSystems, setPosSystems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [selectedPos, setSelectedPos] = useState(null);
  const [newPos, setNewPos] = useState({ name: '', current_api_url: '', current_api_key: '' });

  useEffect(() => {
    fetchPosSystems();
  }, []);

  const fetchPosSystems = async () => {
    try {
      const response = await getPosSystems();
      setPosSystems(response.data);
    } catch (error) {
      console.error('Error fetching POS systems:', error);
    }
  };

  const handleDeleteClick = (pos) => {
    setSelectedPos(pos);
    setOpenDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      console.log(`Attempting to delete POS with ID: ${selectedPos.pos_id}`);
      await deletePos(selectedPos.pos_id);
      setPosSystems(posSystems.filter(pos => pos.pos_id !== selectedPos.pos_id));
      setOpenDialog(false);
    } catch (error) {
      console.error('Error deleting POS system:', error);
      alert('Failed to delete POS system. Please try again later.');
    }
  };

  const handleAddPos = async () => {
    try {
      await addPos(newPos);
      fetchPosSystems();
      setOpenAddDialog(false);
      setNewPos({ name: '', current_api_url: '', current_api_key: '' });
    } catch (error) {
      console.error('Error adding POS system:', error);
    }
  };

  return (
    <StyledContainer>
      <Title variant="h4" gutterBottom>
        POS Management
      </Title>
      <CustomButton
        startIcon={<Add />}
        onClick={() => setOpenAddDialog(true)}
        style={{ marginBottom: '20px' }}
      >
        Add POS
      </CustomButton>
      <Grid container spacing={3}>
        {posSystems.map((pos) => (
          <Grid item xs={12} sm={6} md={4} key={pos.pos_id}>
            <StyledCard>
              <CardContent>
                <Typography variant="h6" component="h2">
                  {pos.name}
                </Typography>
                <Typography color="textSecondary">
                  URL: <StyledLink href={pos.current_api_url} target="_blank" rel="noopener noreferrer">
                    {pos.current_api_url}
                  </StyledLink>
                </Typography>
              </CardContent>
              <IconButton
                onClick={() => handleDeleteClick(pos)}
                color="error"
                style={{ alignSelf: 'flex-end', margin: '8px' }}
              >
                <Delete />
              </IconButton>
            </StyledCard>
          </Grid>
        ))}
      </Grid>

      <WarningDialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          <Box display="flex" alignItems="center" flexDirection="column">
            <WarningIcon />
            <Typography variant="h6">Warning: Permanent Deletion</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <AnimatedText variant="body1" paragraph delay={0}>
            You are about to delete the POS system "{selectedPos?.name}".
          </AnimatedText>
          <AnimatedText variant="body1" paragraph delay={2}>
            This action will permanently delete:
          </AnimatedText>
          <Box component="ul" sx={{ padding: 0 }}>
            <AnimatedText component="li" delay={4}>All associated locations</AnimatedText>
            <AnimatedText component="li" delay={5}>All items in these locations</AnimatedText>
            <AnimatedText component="li" delay={6}>All replenishment levels and history</AnimatedText>
            <AnimatedText component="li" delay={7}>All related settings and data</AnimatedText>
          </Box>
          <AnimatedText variant="body1" paragraph delay={9}>
            This action cannot be undone. Are you absolutely sure?
          </AnimatedText>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => setOpenDialog(false)}>Cancel</CustomButton>
          <CustomButton onClick={handleDeleteConfirm} color="error">
            I understand, delete everything
          </CustomButton>
        </DialogActions>
      </WarningDialog>

      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
        <DialogTitle>Add New POS System</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="POS Name"
            fullWidth
            value={newPos.name}
            onChange={(e) => setNewPos({ ...newPos, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="API URL"
            fullWidth
            value={newPos.current_api_url}
            onChange={(e) => setNewPos({ ...newPos, current_api_url: e.target.value })}
          />
          <TextField
            margin="dense"
            label="API Key"
            fullWidth
            value={newPos.current_api_key}
            onChange={(e) => setNewPos({ ...newPos, current_api_key: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => setOpenAddDialog(false)}>Cancel</CustomButton>
          <CustomButton onClick={handleAddPos}>Add</CustomButton>
        </DialogActions>
      </Dialog>
    </StyledContainer>
  );
}

export default POSManagement;
