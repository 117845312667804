import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Grid, Paper, Typography, Button, CircularProgress, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Camera, Package, Zap, BarChart2, Bell, MessageSquare } from 'lucide-react';
import { getTotalLocations, getTotalOptimization, getRecentUpdates, getInventoryHistory } from '../services/api';
import { formatDistanceToNow } from 'date-fns';
import ReactECharts from 'echarts-for-react';

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: 16,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(8.5px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}));

const IconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 48,
  height: 48,
  borderRadius: '50%',
  backgroundColor: 'var(--color-accent)',
  marginBottom: theme.spacing(2),
}));

const ClickablePaper = styled(StyledPaper)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const QuoteBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  textAlign: 'center',
  padding: theme.spacing(2),
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: 16,
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(8.5px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
}));

const QuoteText = styled(Typography)(({ theme }) => ({
  fontStyle: 'italic',
  marginBottom: theme.spacing(1),
}));

const QuoteAuthor = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
}));

const quotes = [
  {
    "quote": "Success usually comes to those who are too busy to be looking for it.",
    "author": "Henry David Thoreau"
  },
  {
    "quote": "Believe you can and you're halfway there.",
    "author": "Theodore Roosevelt"
  },
  {
    "quote": "Life is 10% what happens to us and 90% how we react to it.",
    "author": "Charles R. Swindoll"
  },
  {
    "quote": "The only limit to our realization of tomorrow is our doubts of today.",
    "author": "Franklin D. Roosevelt"
  },
  {
    "quote": "Don't be afraid to give up the good to go for the great.",
    "author": "John D. Rockefeller"
  },
  {
    "quote": "I find that the harder I work, the more luck I seem to have.",
    "author": "Thomas Jefferson"
  },
  {
    "quote": "Success is not in what you have, but who you are.",
    "author": "Bo Bennett"
  },
  {
    "quote": "It does not matter how slowly you go as long as you do not stop.",
    "author": "Confucius"
  },
  {
    "quote": "Difficulties in life are intended to make us better, not bitter.",
    "author": "Dan Reeves"
  },
  {
    "quote": "Success is not the key to happiness. Happiness is the key to success.",
    "author": "Albert Schweitzer"
  },
  {
    "quote": "Your time is limited, don't waste it living someone else's life.",
    "author": "Steve Jobs"
  },
  {
    "quote": "A journey of a thousand miles begins with a single step.",
    "author": "Lao Tzu"
  },
  {
    "quote": "To succeed in life, you need three things: a wishbone, a backbone, and a funny bone.",
    "author": "Reba McEntire"
  },
  {
    "quote": "The way to get started is to quit talking and begin doing.",
    "author": "Walt Disney"
  },
  {
    "quote": "The only way to do great work is to love what you do.",
    "author": "Steve Jobs"
  },
  {
    "quote": "You are never too old to set another goal or to dream a new dream.",
    "author": "C.S. Lewis"
  },
  {
    "quote": "Success seems to be connected with action. Successful people keep moving.",
    "author": "Conrad Hilton"
  },
  {
    "quote": "The only limit to our realization of tomorrow is our doubts of today.",
    "author": "Franklin D. Roosevelt"
  },
  {
    "quote": "Every artist was first an amateur.",
    "author": "Ralph Waldo Emerson"
  },
  {
    "quote": "Don't watch the clock; do what it does. Keep going.",
    "author": "Sam Levenson"
  },
  {
    "quote": "The best way to predict the future is to create it.",
    "author": "Peter Drucker"
  },
  {
    "quote": "Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work.",
    "author": "Steve Jobs"
  },
  {
    "quote": "If you are not willing to risk the usual, you will have to settle for the ordinary.",
    "author": "Jim Rohn"
  },
  {
    "quote": "Act as if what you do makes a difference. It does.",
    "author": "William James"
  },
  {
    "quote": "The future belongs to those who believe in the beauty of their dreams.",
    "author": "Eleanor Roosevelt"
  },
  {
    "quote": "It is never too late to be what you might have been.",
    "author": "George Eliot"
  },
  {
    "quote": "You don't have to be great to start, but you have to start to be great.",
    "author": "Zig Ziglar"
  },
  {
    "quote": "The only place where success comes before work is in the dictionary.",
    "author": "Vidal Sassoon"
  },
  {
    "quote": "The greatest glory in living lies not in never falling, but in rising every time we fall.",
    "author": "Nelson Mandela"
  },
  {
    "quote": "What you do today can improve all your tomorrows.",
    "author": "Ralph Marston"
  },
  {
    "quote": "Keep your eyes on the stars, and your feet on the ground.",
    "author": "Theodore Roosevelt"
  },
  {
    "quote": "Do what you can, with what you have, where you are.",
    "author": "Theodore Roosevelt"
  },
  {
    "quote": "Success is not how high you have climbed, but how you make a positive difference to the world.",
    "author": "Roy T. Bennett"
  },
  {
    "quote": "We are what we repeatedly do. Excellence, then, is not an act, but a habit.",
    "author": "Aristotle"
  },
  {
    "quote": "The only way to achieve the impossible is to believe it is possible.",
    "author": "Charles Kingsleigh"
  },
  {
    "quote": "Hardships often prepare ordinary people for an extraordinary destiny.",
    "author": "C.S. Lewis"
  },
  {
    "quote": "Dream big and dare to fail.",
    "author": "Norman Vaughan"
  },
  {
    "quote": "Don't be pushed around by the fears in your mind. Be led by the dreams in your heart.",
    "author": "Roy T. Bennett"
  },
  {
    "quote": "The only impossible journey is the one you never begin.",
    "author": "Tony Robbins"
  },
  {
    "quote": "Strive not to be a success, but rather to be of value.",
    "author": "Albert Einstein"
  },
  {
    "quote": "Happiness is not something ready-made. It comes from your own actions.",
    "author": "Dalai Lama"
  },
  {
    "quote": "Your life does not get better by chance, it gets better by change.",
    "author": "Jim Rohn"
  },
  {
    "quote": "You miss 100% of the shots you don't take.",
    "author": "Wayne Gretzky"
  },
  {
    "quote": "Challenges are what make life interesting and overcoming them is what makes life meaningful.",
    "author": "Joshua J. Marine"
  },
  {
    "quote": "It always seems impossible until it’s done.",
    "author": "Nelson Mandela"
  },
  {
    "quote": "The only way to do great work is to love what you do.",
    "author": "Steve Jobs"
  },
  {
    "quote": "Great things are not done by impulse, but by a series of small things brought together.",
    "author": "Vincent Van Gogh"
  },
  {
    "quote": "Do not wait; the time will never be 'just right.' Start where you stand.",
    "author": "Napoleon Hill"
  },
  {
    "quote": "Go the extra mile. It's never crowded.",
    "author": "Wayne Dyer"
  },
  {
    "quote": "Failure is simply the opportunity to begin again, this time more intelligently.",
    "author": "Henry Ford"
  },
  {
    "quote": "Action is the foundational key to all success.",
    "author": "Pablo Picasso"
  },
  {
    "quote": "Success is not final, failure is not fatal: It is the courage to continue that counts.",
    "author": "Winston S. Churchill"
  },
  {
    "quote": "The best time to plant a tree was 20 years ago. The second best time is now.",
    "author": "Chinese Proverb"
  },
  {
    "quote": "Dream as if you’ll live forever. Live as if you’ll die today.",
    "author": "James Dean"
  },
  {
    "quote": "The harder the conflict, the more glorious the triumph.",
    "author": "Thomas Paine"
  },
  {
    "quote": "I can’t change the direction of the wind, but I can adjust my sails to always reach my destination.",
    "author": "Jimmy Dean"
  },
  {
    "quote": "You must be the change you wish to see in the world.",
    "author": "Mahatma Gandhi"
  },
  {
    "quote": "Courage is not the absence of fear, but the triumph over it.",
    "author": "Nelson Mandela"
  },
  {
    "quote": "Start where you are. Use what you have. Do what you can.",
    "author": "Arthur Ashe"
  },
  {
    "quote": "The only way to do great work is to love what you do.",
    "author": "Steve Jobs"
  },
  {
    "quote": "It is our attitude at the beginning of a difficult task which, more than anything else, will affect its successful outcome.",
    "author": "William James"
  },
  {
    "quote": "Nothing is impossible, the word itself says 'I'm possible!'",
    "author": "Audrey Hepburn"
  },
  {
    "quote": "To be a champion, I think you have to see the big picture.",
    "author": "Summer Sanders"
  },
  {
    "quote": "Keep going. Be all in.",
    "author": "Bryan Hutchinson"
  },
  {
    "quote": "Whether you think you can or you think you can't, you're right.",
    "author": "Henry Ford"
  },
  {
    "quote": "All our dreams can come true if we have the courage to pursue them.",
    "author": "Walt Disney"
  },
  {
    "quote": "You have power over your mind – not outside events. Realize this, and you will find strength.",
    "author": "Marcus Aurelius"
  },
  {
    "quote": "Success isn't about how much money you make, it's about the difference you make in people's lives.",
    "author": "Michelle Obama"
  },
  {
    "quote": "Do not be embarrassed by your failures, learn from them and start again.",
    "author": "Richard Branson"
  },
  {
    "quote": "I’ve failed over and over and over again in my life. And that is why I succeed.",
    "author": "Michael Jordan"
  },
  {
    "quote": "In the middle of every difficulty lies opportunity.",
    "author": "Albert Einstein"
  }
];

function Home() {
  const navigate = useNavigate();
  const [totalLocations, setTotalLocations] = useState(null);
  const [totalInventory, setTotalInventory] = useState(null);
  const [optimizationLevel, setOptimizationLevel] = useState(null);
  const [recentUpdates, setRecentUpdates] = useState([]);
  const [inventoryHistory, setInventoryHistory] = useState([]);
  const [currentQuote, setCurrentQuote] = useState({});

  useEffect(() => {
    fetchTotalLocationsAndInventory();
    fetchOptimizationLevel();
    fetchRecentUpdates();
    fetchInventoryHistory(); // Make sure this function is called
    const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
    setCurrentQuote(randomQuote);
  }, []);

  const fetchTotalLocationsAndInventory = async () => {
    try {
      console.log('Fetching total locations and inventory');
      const data = await getTotalLocations();
      console.log('Received data:', data);
      setTotalLocations(data.totalLocations);
      setTotalInventory(data.totalInventory);
    } catch (error) {
      console.error('Error fetching data:', error);
      setTotalLocations(0);
      setTotalInventory(0);
    }
  };

  const fetchOptimizationLevel = async () => {
    try {
      console.log('Fetching optimization level');
      const data = await getTotalOptimization();
      console.log('Received optimization data:', data);
      setOptimizationLevel(data.optimizationLevel);
    } catch (error) {
      console.error('Error fetching optimization level:', error);
      setOptimizationLevel('0%');
    }
  };

  const fetchRecentUpdates = async () => {
    try {
      const data = await getRecentUpdates();
      console.log('Received recent updates:', data);
      setRecentUpdates(data);
    } catch (error) {
      console.error('Error fetching recent updates:', error);
      setRecentUpdates([]);
    }
  };

  const fetchInventoryHistory = async () => {
    try {
      console.log('Fetching inventory history');
      const data = await getInventoryHistory();
      console.log('Received inventory history:', data);
      setInventoryHistory(data);
    } catch (error) {
      console.error('Error fetching inventory history:', error);
      setInventoryHistory([]);
    }
  };

  const handleFeatureRequest = () => {
    const subject = encodeURIComponent('Feature Request for Inventory Management System');
    const body = encodeURIComponent('Hello,\n\nI would like to request a new feature for the Inventory Management System:\n\n[Please describe your feature request here]\n\nThank you!');
    window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=pimbyeli@gmail.com&su=${subject}&body=${body}`, '_blank');
  };

  const handleCardClick = (path) => {
    navigate(path);
  };

  const stats = [
    { name: 'Total Locations', value: totalLocations !== null ? totalLocations : <CircularProgress size={24} />, icon: <Camera size={24} />, path: '/locations' },
    { name: 'Total Inventory', value: totalInventory !== null ? (typeof totalInventory === 'number' ? totalInventory.toLocaleString() : totalInventory) : <CircularProgress size={24} />, icon: <Package size={24} /> },
    { name: 'Optimization Level', value: optimizationLevel !== null ? optimizationLevel : <CircularProgress size={24} />, icon: <Zap size={24} /> },
  ];

  const updates = [
    { id: 1, message: 'New location added: Miami Warehouse', time: '2 hours ago' },
    { id: 2, message: 'Inventory levels updated for all locations', time: '1 day ago' },
    { id: 3, message: 'Optimization algorithm improved', time: '3 days ago' },
  ];

  const formatRelativeTime = (dateString) => {
    const date = new Date(dateString);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  const inventoryChartOption = {
    title: {
      text: 'Total Inventory History'
    },
    tooltip: {
      trigger: 'axis'
    },
    xAxis: {
      type: 'category',
      data: inventoryHistory.map(item => item.date)
    },
    yAxis: {
      type: 'value',
      name: 'Total Inventory'
    },
    series: [{
      data: inventoryHistory.map(item => parseInt(item.total_inventory)),
      type: 'line',
      smooth: true
    }]
  };

  return (
    <StyledContainer>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        Inventory Management Dashboard
      </Typography>
      
      <Grid container spacing={4}>
        {stats.map((item) => (
          <Grid item xs={12} sm={4} key={item.name}>
            <ClickablePaper onClick={() => item.path && handleCardClick(item.path)}>
              <IconWrapper>
                {item.icon}
              </IconWrapper>
              <Typography variant="h4" component="h2" gutterBottom>
                {item.value}
              </Typography>
              <Typography color="textSecondary">{item.name}</Typography>
            </ClickablePaper>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={4} style={{ marginTop: '2rem' }}>
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" component="h3" gutterBottom>
              <BarChart2 size={24} style={{ marginRight: '0.5rem' }} />
              Inventory Overview
            </Typography>
            {inventoryHistory.length > 0 ? (
              <ReactECharts option={inventoryChartOption} style={{ height: 300 }} />
            ) : (
              <div style={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
              </div>
            )}
          </StyledPaper>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" component="h3" gutterBottom>
              <Bell size={24} style={{ marginRight: '0.5rem' }} />
              Recent Updates
            </Typography>
            {recentUpdates.length > 0 ? (
              recentUpdates.map((update) => (
                <div key={update.created_at} style={{ marginBottom: '1rem' }}>
                  <Typography variant="body1">{update.text}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    {formatRelativeTime(update.created_at)}
                  </Typography>
                </div>
              ))
            ) : (
              <Typography color="textSecondary">No recent updates available</Typography>
            )}
          </StyledPaper>
        </Grid>
      </Grid>

      <Grid container justifyContent="center" style={{ marginTop: '2rem' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<MessageSquare />}
          onClick={handleFeatureRequest}
        >
          Request a Feature
        </Button>
      </Grid>

      <Grid container justifyContent="center" style={{ marginTop: '1rem' }}>
        <Link to="/settings" style={{ textDecoration: 'none' }}>
          <Button color="primary">
            Add or Update Settings
          </Button>
        </Link>
      </Grid>

      <QuoteBox>
        <QuoteText variant="h6">"{currentQuote.quote}"</QuoteText>
        <QuoteAuthor variant="subtitle1">- {currentQuote.author}</QuoteAuthor>
      </QuoteBox>

      {/* Feature request modal implementation goes here */}
    </StyledContainer>
  );
}

export default Home;