import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles'; // Add createTheme import
import CssBaseline from '@mui/material/CssBaseline';
import { PrimeReactProvider } from 'primereact/api';
import Login from './components/Login';
import Home from './components/Home';
import Navigation from './components/Navigation'; // Update this import
import Locations from './components/Locations';
import Settings from './components/Settings';
import POSManagement from './components/POSManagement';
import UserManagement from './components/UserManagement';
import { logout } from './services/api';
import LocationDetails from './components/LocationDetails';
import { jwtDecode } from 'jwt-decode';
import Sales from './components/Sales';
import ReplenishLevels from './components/ReplenishLevels';
import DeletedLocations from './components/DeletedLocations';
import Optimize from './components/Optimize';
import TransferOrders from './components/TransferOrders'; // Import the new component
import EmbeddedSheet from './components/EmbeddedSheet';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';

// Hard code the frontend port if needed
const FRONTEND_PORT = 3000;

const theme = createTheme({
  palette: {
    mode: 'light', // Add this line
    primary: {
      main: '#1a2b3c',
    },
    secondary: {
      main: '#20b2aa',
    },
    background: {
      default: '#f5f7fa',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h1: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h2: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h3: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h4: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h5: {
      fontFamily: 'Montserrat, sans-serif',
    },
    h6: {
      fontFamily: 'Montserrat, sans-serif',
    },
  },
});

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkToken = () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          if (decodedToken.exp * 1000 > Date.now()) {
            const user = JSON.parse(localStorage.getItem('user'));
            setUser(user);
          } else {
            handleLogout();
          }
        } catch (error) {
          console.error('Error decoding token:', error);
          handleLogout();
        }
      }
      setLoading(false);
    };

    checkToken();
  }, []);

  const handleLogin = (userData) => {
    setUser(userData);
  };

  const handleLogout = () => {
    logout();
    setUser(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <PrimeReactProvider>
        <CssBaseline />
        <Router>
          {user && <Navigation user={user} onLogout={handleLogout} />}
          <Routes>
            <Route path="/login" element={user ? <Navigate to="/" /> : <Login onLogin={handleLogin} />} />
            <Route path="/" element={user ? <Home /> : <Navigate to="/login" />} />
            <Route path="/locations" element={user ? <Locations /> : <Navigate to="/login" />} />
            <Route path="/locations/:id" element={user ? <LocationDetails /> : <Navigate to="/login" />} />
            <Route path="/locations/:id/replenish-levels" element={user ? <ReplenishLevels /> : <Navigate to="/login" />} />
            <Route path="/settings" element={user && user.role === 'admin' ? <Settings /> : <Navigate to="/" />} />
            <Route path="/pos-management" element={user && user.role === 'admin' ? <POSManagement /> : <Navigate to="/" />} />
            <Route path="/user-management" element={user && user.role === 'admin' ? <UserManagement /> : <Navigate to="/" />} />
            <Route 
              path="/sales" 
              element={user && user.role === 'admin' ? <Sales /> : <Navigate to="/" />}
            />
            <Route path="/deleted-locations" element={<DeletedLocations />} />
            <Route path="/optimize" element={user && user.role === 'admin' ? <Optimize /> : <Navigate to="/" />} />
            <Route 
              path="/transfer-orders"
              element={user && (user.role === 'admin' || user.role === 'manager') ? <TransferOrders /> : <Navigate to="/" />}
            />
            <Route path="/locations/:id/embedded-sheet/:sheetType" element={<EmbeddedSheet />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfService />} />
          </Routes>
        </Router>
      </PrimeReactProvider>
    </ThemeProvider>
  );
}

export default App;